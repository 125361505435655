<template>
    <div class="inner-page">
        <div class="title">
            <div>共{{total}}条</div>
            <button class="btn submit-btn" style="margin-left:auto;" @click="exportReport">导出</button>
        </div>
        <CommonTable :headers="headers" :tableData="tableData" :hasIndex="true" :maxHeight="700" :loading="loading">
            <template slot="name" slot-scope="{ row }">
                <div class="name" @click="goUserDetail(row)">{{row.name}}</div>
            </template>
            <template slot="status" slot-scope="{ row }">
                <div :style="{color: row.status === 'BE_ON_THE_JOB' ? '#27B148' : '#FF2525'}">{{EnumType.userStatus[row.status]}}</div>
            </template>
        </CommonTable>
        <div class="footer">
            <el-pagination background layout="total,sizes,prev, pager, next,jumper" 
                :current-page.sync="params.current"
                :page-sizes="[10, 30, 50, 100]" 
                :page-size.sync="params.size" 
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { queryInnerPage, queryInnerPageBlob } from '@/api/statisticAPI.js';
import {parseFile} from '@/utils/file.js';

export default {
    data(){
        return {
            headers: [
                {
                    label: '姓名',
                    value: 'name',
                    slot: 'name'
                },
                {
                    label: '性别',
                    value: 'sex',
                    enumMap: this.EnumType.sex
                },
                {
                    label: '年龄',
                    value: 'age'
                },
                {
                    label: '民族',
                    value: 'nation'
                },
                {
                    label: '政治面貌',
                    value: 'politicalAppearance',
                    enumMap: this.EnumType.ZZMM
                },
                {
                    label: '学历',
                    value: 'qualification',
                    enumMap: this.EnumType.education
                },
                {
                    label: '学制',
                    value: 'educationalSystem',
                    enumMap: this.EnumType.educationalSystem
                },
                {
                    label: '专业分类',
                    value: 'major',
                    enumMap: this.EnumType.major
                },
                {
                    label: '成员状态',
                    value: 'status',
                    slot: 'status',
                },
                {
                    label: this.$replaceText('部门'),
                    value: 'orgName'
                },
                {
                    label: '职务',
                    value: 'currentPositionNames'
                },
            ],
            tableData: [],
            total: 0,
            params: {
                condition: '',
                courtIds: '',
                reportName: '',
                current: 1,
                size: 30,
            },
            loading: false,
        }
    },
    created(){
        const query = this.$route.query;
        this.params.condition = `${query.rowName}x${query.colName}`;
        this.params.courtIds = query.courtIds;
        this.params.reportName = query.reportName;
    },
    watch: {
        params: {
            handler(val){
				// console.log(111111,val)
                this.getInnerPage();
            },
            deep: true
        }
    },
    methods: {
        exportReport(){
			// const params ={...this.params, export: 'excel'} 
            // queryInnerPageBlob(this.params, params)
            queryInnerPageBlob(this.params, {export: 'excel'}).then(res => {
				console.log(res);
				parseFile(res.data, '数字内页用户信息.xlsx')
			})
        },
        getInnerPage(){
            this.loading = true;
            queryInnerPage(this.params).then(res => {
                this.tableData = res.records;
                this.total = res.total;
            }).finally(() => {
                this.loading = false;
            })
        },
        goUserDetail(row){
            this.$router.push({
                path: '/user-detail',
                query: {
                    userId: row.id
                }
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.inner-page{
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    .title{
        height: 76px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer{
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        width: calc(100% - 40px);
        display: flex;
        justify-content: flex-end;
    }
    .name{
        color: #007AFF;
        cursor: pointer;
    }

}
</style>