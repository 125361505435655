export const data1 = {
    params: {
        row: 16,
        col: 16
    },
    '1x1': {
        width: 140,
    },
    '1x2': {
        width: 140,
    },
    '1x3': {
        label: '合计',
        width: 94,
        direction: 'col',
        value: 'totalCount',
        name: '合计',

    },
    '1x4': {
        label: '院领导',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'instituteLeaderCount',
        name: '院领导',

    },
    '1x5': {
        label: '刑事审判',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'criminalTrialCount',
        name: '刑事审判',
    },
    '1x6': {
        label: '民事审判',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'civilTrialCount',
        name: '民事审判',
    },
    '1x7': {
        label: '行政审判',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'administrativeTrialCount',
        name: '行政审判',
    },
    '1x8': {
        label: '执行',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'executeCount',
        name: '执行',
    },
    '1x9': {
        label: '立案',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'registerCount',
        name: '立案',
    },
    '1x10': {
        label: '审监',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'auditSupervisionCount',
        name: '审监',
    },
    '1x11': {
        label: '审判管理',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'trialManagementCount',
        name: '审判管理',
    },
    '1x12': {
        label: '赔偿',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'compensateCount',
        name: '赔偿',
    },
    '1x13': {
        label: '综合审判',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'comprehensiveTrialCount',
        name: '综合审判',
    },
    '1x14': {
        label: '人民法庭',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'peopleTribunalCount',
        name: '人民法庭',
    },
    '1x15': {
        label: '审判辅助',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'adjudicationAssistanceCount',
        name: '审判辅助',
    },
    '1x16': {
        label: '综合行政',
        type: 'value',
        width: 94,
        direction: 'col',
        value: 'comprehensiveAdministrativeCount',
        name: '综合行政',
    },
    '2x1': {
        label: '合计',
        type: 'computed',
        direction: 'row',
        name: '合计',
        computed: {
            type: 'total',
            row: [3,4,5,6,7,8,10,11,12,13,14,16]
        }
    },
    '2x2': {},
    '3x1': {
        label: '法官',
        rowspan: 7
    },
    '10x1': {
        label: '审判辅助人员',
        rowspan: 6
    },
    '16x1': {
        label: '司法行政人员',
    },
    '3x2': {
        label: '院长',
        type: 'value',
        direction: 'row',
        name: '法官-院长',
    },
    '4x2': {
        label: '副院长',
        type: 'value',
        direction: 'row',
        name: '法官-副院长',
    },
    '5x2': {
        label: '审判委员会委员',
        type: 'value',
        direction: 'row',
        name: '法官-审判委员会委员',
    },
    '6x2': {
        label: '庭长',
        type: 'value',
        direction: 'row',
        name: '法官-庭长',
    },
    '7x2': {
        label: '副庭长',
        type: 'value',
        direction: 'row',
        name: '法官-副庭长',
    },
    '8x2': {
        label: '审判员',
        type: 'value',
        direction: 'row',
        name: '法官-审判员',
    },
    '9x2': {
        label: '法官合计',
        type: 'computed',
        direction: 'row',
        name: '法官-法官合计',
        computed: {
            type: 'total',
            row: [3,4,5,6,7,8]
        }
    },
    '10x2': {
        label: '法官助理',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-法官助理',
    },
    '11x2': {
        label: '书记员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-书记员',
    },
    '12x2': {
        label: '司法警察',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法警察',
    },
    '13x2': {
        label: '司法技术人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法技术人员',
    },
    '14x2': {
        label: '其他审判辅助人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-其他审判辅助人员',
    },
    '15x2': {
        label: '审判辅助人员合计',
        type: 'computed',
        direction: 'row',
        name: '审判辅助人员-审判辅助人员合计',
        computed: {
            type: 'total',
            row: [10,11,12,13,14]
        }
    },
    '16x2': {
        label: '司法行政人员',
        type: 'value',
        direction: 'row',
        name: '司法行政人员-司法行政人员',
    },
}

export const data22 = {
	params: {
		row: 17,
		col: 19
	},
	'1x1': {
		width: 234,
		colspan: 2,
		rowspan: 2,
	},
	'1x3': {
		width: 76,
		rowspan: 2,
		label: '实有人数',
		direction: 'col',
		value: 'totalCount',
		name: '实有人数'
	},
	'1x4': {
		width: 152,
		colspan: 2,
		label: '性别'
	},
	'1x6': {
		width: 152,
		colspan: 2,
		label: '民族'
	},
	'1x8': {
		width: 304,
		colspan: 4,
		label: '政治面貌'
	},
	'1x12': {
		width: 602,
		colspan: 7,
		label: '年龄'
	},
	'1x19': {
		width: 80,
		rowspan: 2,
		label: '平均年龄',
		direction: 'col',
		value: 'avgAge',
		name: '平均年龄'
	},
	'2x4': {
		width: 76,
		label: '男',
		direction: 'col',
		value: 'maleCount',
		name: '性别-男'
	},
	'2x5': {
		width: 76,
		label: '女',
		direction: 'col',
		value: 'femaleCount',
		name: '性别-女'
	},
	'2x6': {
		width: 76,
		label: '汉族',
		direction: 'col',
		value: 'hanNationalityCount',
		name: '民族-汉族'
	},
	'2x7': {
		width: 76,
		label: '少数民族',
		direction: 'col',
		value: 'minorityNationalityCount',
		name: '民族-少数民族'
	},
	'2x8': {
		width: 76,
		label: '中共党员',
		direction: 'col',
		value: 'cpcCount',
		name: '政治面貌-中共党员'
	},
	'2x9': {
		width: 76,
		label: '共青团员',
		direction: 'col',
		value: 'cyCount',
		name: '政治面貌-共青团员'
	},
	'2x10': {
		width: 76,
		label: '民主党派',
		direction: 'col',
		value: 'dncCount',
		name: '政治面貌-民主党派'
	},
	'2x11': {
		width: 76,
		label: '其他',
		direction: 'col',
		value: 'otherCount',
		name: '政治面貌-其他'
	},
	'2x12': {
		width: 76,
		label: '35岁以下',
		direction: 'col',
		value: 'beforeThirtyFiveCount',
		name: '年龄-35岁以下'
	},
	'2x13': {
		width: 76,
		label: '36岁至40岁',
		direction: 'col',
		value: 'thirtySixToFortyCount',
		name: '年龄-36岁至40岁'
	},
	'2x14': {
		width: 76,
		label: '41岁至45岁',
		direction: 'col',
		value: 'fortyOneToFortyFiveCount',
		name: '年龄-41岁至45岁'
	},
	'2x15': {
		width: 76,
		label: '46岁至50岁',
		direction: 'col',
		value: 'fortySixToFiftyCount',
		name: '年龄-46岁至50岁'
	},
	'2x16': {
		width: 76,
		label: '51岁至55岁',
		direction: 'col',
		value: 'fiftyOneToFiftyFiveCount',
		name: '年龄-51岁至55岁'
	},
	'2x17': {
		width: 76,
		label: '56岁至60岁',
		direction: 'col',
		value: 'fiftySixToSixtyCount',
		name: '年龄-56岁至60岁'
	},
	'2x18': {
		width: 76,
		label: '61岁及以上',
		direction: 'col',
		value: 'sixtyOneAfterCount',
		name: '年龄-61岁及以上'
	},
	'3x1': {
		label: '合计',
		type: 'value',
		direction: 'row',
		name: '合计',
		computed: {
			type: 'total',
			row: [4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17]
		}
	},
	'3x2': {},
    '4x1': {
        label: '法官',
        rowspan: 7
    },
    '11x1': {
        label: '审判辅助人员',
        rowspan: 6
    },
    '17x1': {
        label: '司法行政人员',
    },
    '4x2': {
        label: '院长',
        type: 'value',
        direction: 'row',
        name: '法官-院长',
    },
    '5x2': {
        label: '副院长',
        type: 'value',
        direction: 'row',
        name: '法官-副院长',
    },
    '6x2': {
        label: '审判委员会委员',
        type: 'value',
        direction: 'row',
        name: '法官-审判委员会委员',
    },
    '7x2': {
        label: '庭长',
        type: 'value',
        direction: 'row',
        name: '法官-庭长',
    },
    '8x2': {
        label: '副庭长',
        type: 'value',
        direction: 'row',
        name: '法官-副庭长',
    },
    '9x2': {
        label: '审判员',
        type: 'value',
        direction: 'row',
        name: '法官-审判员',
    },
    '10x2': {
        label: '法官合计',
        type: 'computed',
        direction: 'row',
        name: '法官-法官合计',
        computed: {
            type: 'total',
            row: [4,5,6,7,8,9]
        }
    },
    '10x19': {
        type: 'computed',
        computed: {
            type: 'average',
            position: [
                [4,19],
                [5,19],
                [6,19],
                [7,19],
                [8,19],
                [9,19],
            ]
        }
    },
    '11x2': {
        label: '法官助理',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-法官助理',
    },
    '12x2': {
        label: '书记员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-书记员',
    },
    '13x2': {
        label: '司法警察',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法警察',
    },
    '14x2': {
        label: '司法技术人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法技术人员',
    },
    '15x2': {
        label: '其他审判辅助人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-其他审判辅助人员',
    },
    '16x2': {
        label: '审判辅助人员合计',
        type: 'computed',
        direction: 'row',
        name: '审判辅助人员-审判辅助人员合计',
        computed: {
            type: 'total',
            row: [11,12,13,14,15]
        }
    },
    '16x19': {
        type: 'computed',
        computed: {
            type: 'average',
            position: [
                [11,19],
                [12,19],
                [13,19],
                [14,19],
                [15,19],
            ]
        }
    },
    '17x2': {
        label: '司法行政人员',
        type: 'value',
        direction: 'row',
        name: '司法行政人员-司法行政人员',
    },
}

export const data33= {
    params: {
        row: 17,
        col: 16
    },
    '1x1': {
        width: 284,
        colspan: 2,
        rowspan: 2,
    },
    '1x3': {
        label: '合计',
        width: 94,
        rowspan: 2,
        direction: 'col',
        value: 'totalCount',
        name: '合计'
    },
    '1x4': {
        label: '博士研究生',
        colspan: 3,
        width: 282,
    },
    '1x7': {
        label: '硕士研究生',
        colspan: 3,
        width: 282,
    },
    '1x10': {
        label: '大学',
        colspan: 3,
        width: 282,
    },
    '1x13': {
        label: '大专',
        colspan: 3,
        width: 282,
    },
    '1x16': {
        label: '中专及以下',
        rowspan: 2,
        width: 94,
        direction: 'col',
        value: 'beforeJuniorCount',
        name: '中专及以下'
    },
    '2x4': {
        label: '小计',
        width: 94,
        direction: 'col',
        value: 'doctorCount',
        name: '博士研究生-小计'
    },
    '2x5': {
        label: '法律专业',
        width: 94,
        direction: 'col',
        value: 'doctorLegalCount',
        name: '博士研究生-法律专业'
    },
    '2x6': {
        label: '其他专业',
        width: 94,
        direction: 'col',
        value: 'doctorOtherCount',
        name: '博士研究生-其他专业'
    },
    '2x7': {
        label: '小计',
        width: 94,
        direction: 'col',
        value: 'masterCount',
        name: '硕士研究生-小计'
    },
    '2x8': {
        label: '法律专业',
        width: 94,
        direction: 'col',
        value: 'masterLegalCount',
        name: '硕士研究生-法律专业'
    },
    '2x9': {
        label: '其他专业',
        width: 94,
        direction: 'col',
        value: 'masterOtherCount',
        name: '硕士研究生-其他专业'
    },
    '2x10': {
        label: '小计',
        width: 94,
        direction: 'col',
        value: 'underGraduateCount',
        name: '大学-小计'
    },
    '2x11': {
        label: '法律专业',
        width: 94,
        direction: 'col',
        value: 'underGraduateLegalCount',
        name: '大学-法律专业'
    },
    '2x12': {
        label: '其他专业',
        width: 94,
        direction: 'col',
        value: 'underGraduateOtherCount',
        name: '大学-其他专业'
    },
    '2x13': {
        label: '小计',
        width: 94,
        direction: 'col',
        value: 'juniorCount',
        name: '大专-小计'
    },
    '2x14': {
        label: '法律专业',
        width: 94,
        direction: 'col',
        value: 'juniorLegalCount',
        name: '大专-法律专业'
    },
    '2x15': {
        label: '其他专业',
        width: 94,
        direction: 'col',
        value: 'juniorOtherCount',
        name: '大专-其他专业'
    },
    '3x1': {
        label: '合计',
        type: 'value',
        direction: 'row',
		name: '合计',
        computed: {
            type: 'total',
            row: [4,5,6,7,8,9,11,12,13,14,15,17]
        }
    },
    '3x2':{},
    '4x1': {
        label: '法官',
        rowspan: 7
    },
    '11x1': {
        label: '审判辅助人员',
        rowspan: 6
    },
    '17x1': {
        label: '司法行政人员',
    },
    '4x2': {
        label: '院长',
        type: 'value',
        direction: 'row',
        name: '法官-院长',
        value: '院长',
    },
    '5x2': {
        label: '副院长',
        type: 'value',
        direction: 'row',
        name: '法官-副院长',
        value: '副院长',
    },
    '6x2': {
        label: '审判委员会委员',
        type: 'value',
        direction: 'row',
        name: '法官-审判委员会委员',
        value: '审判委员会委员',
    },
    '7x2': {
        label: '庭长',
        type: 'value',
        direction: 'row',
        name: '法官-庭长',
        value: '庭长',
    },
    '8x2': {
        label: '副庭长',
        type: 'value',
        direction: 'row',
        name: '法官-副庭长',
        value: '副庭长',
    },
    '9x2': {
        label: '审判员',
        type: 'value',
        direction: 'row',
        name: '法官-审判员',
        value: '审判员',
    },
    '10x2': {
        label: '法官合计',
        type: 'computed',
        direction: 'row',
        name: '法官-法官合计',
        computed: {
            type: 'total',
            row: [4,5,6,7,8,9]
        }
    },
    '11x2': {
        label: '法官助理',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-法官助理',
        value: '法官助理'
    },
    '12x2': {
        label: '书记员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-书记员',
        value: '书记员'
    },
    '13x2': {
        label: '司法警察',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法警察',
        value: '司法警察'
    },
    '14x2': {
        label: '司法技术人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-司法技术人员',
        value: '司法技术人员'
    },
    '15x2': {
        label: '其他审判辅助人员',
        type: 'value',
        direction: 'row',
        name: '审判辅助人员-其他审判辅助人员',
        value: '其他审判辅助人员'
    },
    '16x2': {
        label: '审判辅助人员合计',
        type: 'computed',
        direction: 'row',
        name: '审判辅助人员-审判辅助人员合计',
        computed: {
            type: 'total',
            row: [11,12,13,14,15]
        }
    },
    '17x2': {
        label: '司法行政人员',
        type: 'value',
        direction: 'row',
        name: '司法行政人员-司法行政人员',
        value: '司法行政人员'
    },
}

// 库1 
const libHeader1 = (colSize, computed = false, type = 'average') => ({
	'4x1': {
		label: '人员分类',
		rowspan: 3,
		direction: 'row'
	},
	'7x1': {
		label: '法官等级',
		rowspan: 6,
		direction: 'row'
	},
	'13x1': {
		label: '职务',
		rowspan: 6,
		direction: 'row'
	},
	'19x1': {
		label: '职级',
		rowspan: 5,
		direction: 'row'
	},
	'4x2': {
		label: '员额法官',
		type: 'value',
		direction: 'row',
		name: '人员分类-员额法官',
	},
	'5x2': {
		label: '综合行政',
		type: 'value',
		direction: 'row',
		name: '人员分类-综合行政',
	},
	'6x2': {
		label: '人员分类合计',
		type: 'computed',
		direction: 'row',
		name: '人员分类-人员分类合计',
		computed: {
			type: 'total',
			row: [4, 5]
		}
	},
	[`6x${colSize}`]: {
		direction: 'col-row',
		name: '人员分类-人员分类合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,
			position: [
				[4, colSize],
				[5, colSize]
			]
		}
	},
	'7x2': {
		label: '一级高级法官',
		type: 'value',
		direction: 'row',
		name: '法官等级-一级高级法官',
	},
	'8x2': {
		label: '二级高级法官',
		type: 'value',
		direction: 'row',
		name: '法官等级-二级高级法官',
	},
	'9x2': {
		label: '三级高级法官',
		type: 'value',
		direction: 'row',
		name: '法官等级-三级高级法官',
	},
	'10x2': {
		label: '四级高级法官',
		type: 'value',
		direction: 'row',
		name: '法官等级-四级高级法官',
	},
	'11x2': {
		label: '未入额',
		type: 'value',
		direction: 'row',
		name: '法官等级-未入额',
	},
	'12x2': {
		label: '法官等级合计',
		type: 'computed',
		direction: 'row',
		name: '法官等级-法官等级合计',
		computed: {
			type: 'total',
			row: [7, 8, 9, 10, 11]
		}

	},
	[`12x${colSize}`]: {
		direction: 'col-row',
		name: '法官等级-法官等级合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,             // 人数合计的列号
			position: [
				[7, colSize],
				[8, colSize],
				[9, colSize],
				[10, colSize],
				[11, colSize],
			]
		}
	},
	'13x2': {
		label: '院长',
		type: 'value',
		direction: 'row',
		name: '职务-院长',
	},
	'14x2': {
		label: '副院长',
		type: 'value',
		direction: 'row',
		name: '职务-副院长',
	},
	'15x2': {
		label: '政治部主任',
		type: 'value',
		direction: 'row',
		name: '职务-政治部主任',
	},
	'16x2': {
		label: '审判委员会专职委员',
		type: 'value',
		direction: 'row',
		name: '职务-审判委员会专职委员',
	},
	'17x2': {
		label: '其他职务',
		type: 'value',
		direction: 'row',
		name: '职务-其他职务',
	},
	'18x2': {
		label: '职务合计',
		type: 'computed',
		direction: 'row',
		name: '职务-职务合计',
		computed: {
			type: 'total',
			row: [13, 14, 15, 16, 17]
		}
	},
	[`18x${colSize}`]: {
		direction: 'col-row',
		name: '职务-职务合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,
			position: [
				[13, colSize],
				[14, colSize],
				[15, colSize],
				[16, colSize],
				[17, colSize],
			]
		}
	},
	'19x2': {
		label: '一级调研员',
		type: 'value',
		direction: 'row',
		name: '职级-一级调研员',
	},
	'20x2': {
		label: '二级调研员',
		type: 'value',
		direction: 'row',
		name: '职级-二级调研员',
	},
	'21x2': {
		label: '三级调研员',
		type: 'value',
		direction: 'row',
		name: '职级-三级调研员',
	},
	'22x2': {
		label: '四级调研员',
		type: 'value',
		direction: 'row',
		name: '职级-四级调研员',
	},
	'23x2': {
		label: '职级合计',
		type: 'computed',
		direction: 'row',
		name: '职级-职级合计',
		computed: {
			type: 'total',
			row: [19, 20, 21, 22]
		}
	},
	[`23x${colSize}`]: {
		direction: 'col-row',
		name: '职级-职级合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,
			position: [
				[19, colSize],
				[20, colSize],
				[21, colSize],
				[22, colSize]
			]
		}
	}
})

// 库2 库3
const libHeader2 = (colSize, computed = false, type='average') =>({
	'4x1': {
		label: '法官',
		rowspan: 7,
		direction: 'row'
	},
	'11x1': {
		label: '审判辅助人员',
		rowspan: 6,
		direction: 'row'
	},
	'17x1': {
		label: '司法行政人员',
		direction: 'row'
	},
	'4x2': {
		label: '院长',
		type: 'value',
		direction: 'row',
		name: '法官-院长',
	},
	'5x2': {
		label: '副院长',
		type: 'value',
		direction: 'row',
		name: '法官-副院长',
	},
	'6x2': {
		label: '审判委员会委员',
		type: 'value',
		direction: 'row',
		name: '法官-审判委员会委员',
	},
	'7x2': {
		label: '庭长',
		type: 'value',
		direction: 'row',
		name: '法官-庭长',
	},
	'8x2': {
		label: '副庭长',
		type: 'value',
		direction: 'row',
		name: '法官-副庭长',
	},
	'9x2': {
		label: '审判员',
		type: 'value',
		direction: 'row',
		name: '法官-审判员',
	},
	'10x2': {
		label: '法官合计',
		type: 'computed',
		direction: 'row',
		name: '法官-法官合计',
		computed: {
			type: 'total',
			row: [4, 5, 6, 7, 8, 9]
		}
	},
	[`10x${colSize}`]: {
		direction: 'col-row',
		name: '法官-法官合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,
			position: [
				[4, colSize],
				[5, colSize],
				[6, colSize],
				[7, colSize],
				[8, colSize],
				[9, colSize],
			]
		}
	},
	'11x2': {
		label: '法官助理',
		type: 'value',
		direction: 'row',
		name: '审判辅助人员-法官助理',
	},
	'12x2': {
		label: '书记员',
		type: 'value',
		direction: 'row',
		name: '审判辅助人员-书记员',
	},
	'13x2': {
		label: '司法警察',
		type: 'value',
		direction: 'row',
		name: '审判辅助人员-司法警察',
	},
	'14x2': {
		label: '司法技术人员',
		type: 'value',
		direction: 'row',
		name: '审判辅助人员-司法技术人员',
	},
	'15x2': {
		label: '其他审判辅助人员',
		type: 'value',
		direction: 'row',
		name: '审判辅助人员-其他审判辅助人员',
	},
	'16x2': {
		label: '审判辅助人员合计',
		type: 'computed',
		direction: 'row',
		name: '审判辅助人员-审判辅助人员合计',
		computed: {
			type: 'total',
			row: [11, 12, 13, 14, 15]
		}
	},
	[`16x${colSize}`]: {
		direction: 'col-row',
		name: '审判辅助人员-审判辅助人员合计',
		type: computed ? 'computed' : 'value',
		computed: {
			type,
			col: 3,
			position: [
				[11, colSize],
				[12, colSize],
				[13, colSize],
				[14, colSize],
				[15, colSize],
			]
		}
	},
	'17x2': {
		label: '司法行政人员',
		type: 'value',
		direction: 'row',
		name: '司法行政人员-司法行政人员',
	},
})

const classifyHorizontalHeader = (rowSize, colSize) => ({
	params: {
		row: rowSize,
		col: colSize,
		lastColName: '平均年龄'
	},
	'1x1': {
		width: 234,
		colspan: 2,
		rowspan: 2,
	},
	'1x3': {
		width: 76,
		rowspan: 2,
		label: '实有人数',
		direction: 'col',
		value: 'totalCount',
		name: '实有人数'
	},
	'1x4': {
		width: 152,
		colspan: 2,
		label: '性别'
	},
	'1x6': {
		width: 152,
		colspan: 2,
		label: '民族'
	},
	'1x8': {
		width: 304,
		colspan: 4,
		label: '政治面貌'
	},
	'1x12': {
		width: 602,
		colspan: 7,
		label: '年龄'
	},
	'1x19': {
		width: 80,
		rowspan: 2,
		label: '平均年龄',
		direction: 'col',
		value: 'avgAge',
		name: '平均年龄'
	},
	'2x4': {
		width: 76,
		label: '男',
		direction: 'col',
		value: 'maleCount',
		name: '性别-男'
	},
	'2x5': {
		width: 76,
		label: '女',
		direction: 'col',
		value: 'femaleCount',
		name: '性别-女'
	},
	'2x6': {
		width: 76,
		label: '汉族',
		direction: 'col',
		value: 'hanNationalityCount',
		name: '民族-汉族'
	},
	'2x7': {
		width: 76,
		label: '少数民族',
		direction: 'col',
		value: 'minorityNationalityCount',
		name: '民族-少数民族'
	},
	'2x8': {
		width: 76,
		label: '中共党员',
		direction: 'col',
		value: 'cpcCount',
		name: '政治面貌-中共党员'
	},
	'2x9': {
		width: 76,
		label: '共青团员',
		direction: 'col',
		value: 'cyCount',
		name: '政治面貌-共青团员'
	},
	'2x10': {
		width: 76,
		label: '民主党派',
		direction: 'col',
		value: 'dncCount',
		name: '政治面貌-民主党派'
	},
	'2x11': {
		width: 76,
		label: '其他',
		direction: 'col',
		value: 'otherCount',
		name: '政治面貌-其他'
	},
	'2x12': {
		width: 76,
		label: '35岁以下',
		direction: 'col',
		value: 'beforeThirtyFiveCount',
		name: '年龄-35岁以下'
	},
	'2x13': {
		width: 76,
		label: '36岁至40岁',
		direction: 'col',
		value: 'thirtySixToFortyCount',
		name: '年龄-36岁至40岁'
	},
	'2x14': {
		width: 76,
		label: '41岁至45岁',
		direction: 'col',
		value: 'fortyOneToFortyFiveCount',
		name: '年龄-41岁至45岁'
	},
	'2x15': {
		width: 76,
		label: '46岁至50岁',
		direction: 'col',
		value: 'fortySixToFiftyCount',
		name: '年龄-46岁至50岁'
	},
	'2x16': {
		width: 76,
		label: '51岁至55岁',
		direction: 'col',
		value: 'fiftyOneToFiftyFiveCount',
		name: '年龄-51岁至55岁'
	},
	'2x17': {
		width: 76,
		label: '56岁至60岁',
		direction: 'col',
		value: 'fiftySixToSixtyCount',
		name: '年龄-56岁至60岁'
	},
	'2x18': {
		width: 76,
		label: '61岁及以上',
		direction: 'col',
		value: 'sixtyOneAfterCount',
		name: '年龄-61岁及以上'
	},
	'3x1': {
		label: '合计',
		type: 'value',
		direction: 'row',
		name: '合计',
		computed: {
			type: 'total',
			row: [4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17]
		}
	},
	'3x2': {},
})


const educationHorizontalHeader = (rowSize, colSize) => ({
	params: {
		row: rowSize,
		col: colSize,
		lastColName: '中专及以下'
	},
	'1x1': {
		width: 284,
		colspan: 2,
		rowspan: 2,
	},
	'1x3': {
		label: '合计',
		width: 94,
		rowspan: 2,
		direction: 'col',
		value: 'totalCount',
		name: '合计'
	},
	'1x4': {
		label: '博士研究生',
		colspan: 3,
		width: 282,
	},
	'1x7': {
		label: '硕士研究生',
		colspan: 3,
		width: 282,
	},
	'1x10': {
		label: '大学',
		colspan: 3,
		width: 282,
	},
	'1x13': {
		label: '大专',
		colspan: 3,
		width: 282,
	},
	'1x16': {
		label: '中专及以下',
		rowspan: 2,
		width: 94,
		direction: 'col',
		value: 'beforeJuniorCount',
		name: '中专及以下'
	},
	'2x4': {
		label: '小计',
		width: 94,
		direction: 'col',
		value: 'doctorCount',
		name: '博士研究生-小计'
	},
	'2x5': {
		label: '法律专业',
		width: 94,
		direction: 'col',
		value: 'doctorLegalCount',
		name: '博士研究生-法律专业'
	},
	'2x6': {
		label: '其他专业',
		width: 94,
		direction: 'col',
		value: 'doctorOtherCount',
		name: '博士研究生-其他专业'
	},
	'2x7': {
		label: '小计',
		width: 94,
		direction: 'col',
		value: 'masterCount',
		name: '硕士研究生-小计'
	},
	'2x8': {
		label: '法律专业',
		width: 94,
		direction: 'col',
		value: 'masterLegalCount',
		name: '硕士研究生-法律专业'
	},
	'2x9': {
		label: '其他专业',
		width: 94,
		direction: 'col',
		value: 'masterOtherCount',
		name: '硕士研究生-其他专业'
	},
	'2x10': {
		label: '小计',
		width: 94,
		direction: 'col',
		value: 'underGraduateCount',
		name: '大学-小计'
	},
	'2x11': {
		label: '法律专业',
		width: 94,
		direction: 'col',
		value: 'underGraduateLegalCount',
		name: '大学-法律专业'
	},
	'2x12': {
		label: '其他专业',
		width: 94,
		direction: 'col',
		value: 'underGraduateOtherCount',
		name: '大学-其他专业'
	},
	'2x13': {
		label: '小计',
		width: 94,
		direction: 'col',
		value: 'juniorCount',
		name: '大专-小计'
	},
	'2x14': {
		label: '法律专业',
		width: 94,
		direction: 'col',
		value: 'juniorLegalCount',
		name: '大专-法律专业'
	},
	'2x15': {
		label: '其他专业',
		width: 94,
		direction: 'col',
		value: 'juniorOtherCount',
		name: '大专-其他专业'
	},
	'3x1': {
		label: '合计',
		type: 'value',
		direction: 'row',
		name: '合计',
		computed: {
			type: 'total',
			row: [4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17]
		}
	},
	'3x2': {},
})


const classifyColSize = 19
const educationColSize = 16
// 库 1
const rowSize1 = 23
// 法院工作人员基本情况统计表
export const data4 = {
	...classifyHorizontalHeader(rowSize1, classifyColSize),
	...libHeader1(classifyColSize, true)
}

// 法院工作人员最高学历情况统计表
export const data5 = {
	...educationHorizontalHeader(rowSize1, educationColSize),
	...libHeader1(educationColSize, false, 'total')
}

// 库 2
const rowSize2= 17

export const data2 = {
	...classifyHorizontalHeader(rowSize2, classifyColSize),
	...libHeader2(classifyColSize, true)
}

export const data3 = {
	...educationHorizontalHeader(rowSize2, educationColSize),
	...libHeader2(educationColSize, false, 'total')
}


// 库1 法院工作人员最高学历情况统计表


//  label -- 表格显示内容
//  type --  决定是从后端取值还是计算值
//  direction -- 行列批量赋值,代表一整行/列都是这个逻辑
//  computed: {
//      type  -- 'total'/'average'  --  计算是求和或者平均值
//      position -- 确定计算取得是哪些值
//      row --  单纯为了少些几个对象，也是批量赋值计算属性        
//  }




// // const x = {
// //     label: '男',
// //     value: '女'   
// // }

// const name = {
//     key: '法官-院长x少数民族',

//     // all: [
//     //     '男,女,汉族'
//     // ]

// }

// const x = {
//     '法官': 'JUDGE',
    
// }

// '法官-院长'



// // y -> 院领导-----------// 
// const keyMap = {
//     positionNames: ['院长',''],
//     orgNames: ['院领导','刑事审判'],
//     sex: ['男','女'],
//     nation: ['汉族','少数民族'],
//     degree: ['BACHELOR','MASTER','DOCTOR'],
//     DOCTOR: [''],
//     major: ['法律专业'],
    
// }

// const z = {
//     '博士研究生': 'DOCTOR-POSTGRADUATE',
    
// }


// const valueMap = {
//     '院领导': '院领导',
//     '其他': 'OTHER'
// }
