<template>
    <div class="report-form">
        <div class="inner-page-container" v-if="showInnerPage">
            <InnerPage></InnerPage>
        </div>
        <div class="report-table" v-else>
            <div class="org-tree-container">
                <OrgTree :orgList="orgList" :checkboxValues.sync="checkboxValues" :hasSelect="true"></OrgTree>
            </div>
            <div ref="content" class="content" v-myLoading="loading">
                <Search :paramsHeaders="searchHeaders" :params="params" :hasSearch="false">
                    <template slot="operate">
                        <button class="btn submit-btn ml10" @click="getCountData">统计</button>
                        <button class="btn submit-btn" style="margin-left:auto;" @click="exportReport" :disabled="disabled">导出</button>
                    </template>
                </Search>
                <div class="title">
                    {{reportName}}
                </div>
                <Report ref="report" v-if="template && checkboxValues.length" :template="template" :data="data" @tdClick="tdClick"></Report>
                <div class="prompt" v-else>请先选择需要统计的【法院】和【报表类型】</div>
            </div>
            
        </div>

    </div>
</template>

<script>
import OrgTree from '@/components/orgTree';
import Report from './Report.vue';
import { getOrgList } from '@/api/hrAPI.js';
import { reportDeptCount , reportBaseInfo , reportEducation , reportEducationFullTime } from '@/api/statisticAPI.js';
import { traverse , copyTree } from '@/utils/handle.js';
import { data1 , data2 ,data3, data4, data5 } from './map.js';
import InnerPage from './InnerPage.vue';

export default {
    components: {
        OrgTree,
        Report,
        InnerPage
    },
    data(){
        return {
            orgList: [],
            checkboxValues: [],
            searchHeaders: [
                {
                    label: '选择报表',
                    value: 'report',
                    type: 'select',
                    width: 300,
                    typeList: []
                },
                {
                    label: '法院级别',
                    value: 'level',
                    type: 'select',
                    typeList: [
                        {
                            label: '所有法院',
                            value: 'all',
							key: 0
                        },
                        {
                            label: '高院',
                            value: 'SENIOR',
							key: 3
                        },
                        {
                            label: '中院',
                            value: 'INTERMEDIATE',
							key: 2
                        },
                        {
                            label: '基层院',
                            value: 'JUNIOR',
							key: 1
                        },
                    ]
                }
            ],
            params: {
                report: '',
                level: '',
            },
            template: null,
            data: [],
            loading: false,
			reportName: ''
        }
    },
    computed: {
        showInnerPage(){
            return this.$route.query.showInnerPage;
        },
		userPermissions() {
			return this.$store.getters?.permissions || [];
		},
		disabled() {
			return !this.params.level || !this.params.report
		}
    },
    watch: {
        'params.level': {
            handler(val){
                // console.log(val);
                const list = [];
                traverse(this.orgList,org => {
                    if(org.id.includes('parent')){
                        return;
                    }
                    if(val === 'all'){
                        list.push(org.id);
                    }else{
                        if(org.type === val){
                            list.push(org.id);
                        }
                    }
                })
                // console.log(list);
                this.checkboxValues = list;
            },
        },
        'params.report': {
            handler(val){
                switch(val){
                    case '各部门人数统计表':
                        this.template = data1;
                    break;
                    case '法院工作人员基本情况统计表':
                        // this.template = data2;
                        this.template = this.$getCurrentLib() ? data2 : data4;
                    break;
                    case '法院工作人员最高学历情况统计表':
                        // this.template = data3;
						 this.template = this.$getCurrentLib() ? data3 : data5;
                    break;
                    case '法院工作人员全日制学历情况统计表':
						this.template = this.$getCurrentLib() ? data3 : data5;
                        // this.template = data3;
                    break;
                    default:
                        this.template = '';
                    break;
                }
                // this.getCountData();
            },
        },
        checkboxValues: {
            handler(val){
                // this.getCountData();
            }
        },	

    },
    created(){
        if(!this.showInnerPage){
            this.orgListInit();
        }
		this.setTypeList()
    },
    methods: {
		setTypeList() {
			const typeList = [
    			// {
    			//     label: '各部门人数统计表',
    			//     value: '各部门人数统计表'
    			// },
    			{
    			    label: '法院工作人员基本情况统计表',
    			    value: '法院工作人员基本情况统计表',
					url: '/hrms/report/query-base-info'
    			},
    			{
    			    label: '法院工作人员最高学历情况统计表',
    			    value: '法院工作人员最高学历情况统计表',
					url: '/hrms/report/query-highest-qualification'
    			},
    			{
    			    label: '法院工作人员全日制学历情况统计表',
    			    value: '法院工作人员全日制学历情况统计表',
					url: '/hrms/report/query-full-time'
    			},
			]		

			const libName = this.$getCurrentLib()
			typeList.forEach(t => {
				if(libName) t.url = t.url.replace('/hrms', `/hrms/${libName}`)
			})
			
			this.searchHeaders[0].typeList = typeList.filter(t => this.userPermissions.includes(t.url))
		},
        orgListInit(){
            let index = 1;
            getOrgList().then(res => {
                this.orgList = copyTree(res,org => {
                    if(org.type === 'DEPT'){
                        return null;
                    }
                    if(org.children && org.children.length){
                        if(org.children.find(child => child.type !== 'DEPT')){             //  子集是否有非部门数据
                            if(!org.children.find(item => item.name === '内设机构')){       //  添加内设机构
                                org.children.unshift({
                                    ...org,
                                    name: '内设机构',
                                    children: [],
                                })
                                org.id = `parent${index ++}`;
                            }
                        }
                        
                    }
                    return {
                        name: org.name,
                        id: org.id,
                        type: org.type,
                    }
                })

				this.filterLevel(this.orgList);
            })
        },
		filterLevel(list) {
			if(list.length) {
				const typeList = this.searchHeaders[1].typeList
				const target = typeList.find(item => item.value === list[0].type)
				
				this.searchHeaders[1].typeList = typeList.filter(item => item.key <= target.key)
			}	
		},
		checkBeforeGet() {
			// const unselected = Object.keys(this.params).some((key) => !this.params[key])
			const unselectReport = !!!this.params.report
			const unselectLevel = !!!this.params.report || !!!this.checkboxValues.length
			if(unselectReport || unselectLevel) {
				this.$message({
          			message: '请先选择法院和报表',
          			type: 'warning'
        		});	
			}
		},
        async getCountData(){
			this.checkBeforeGet()
			this.reportName = this.params.report
            const courtIds = this.checkboxValues.filter(item => !item.startsWith('parent')).join(',');
            if(!courtIds){
                return;
            }
            this.data = [];
            this.loading = true;
            try {
                switch(this.params.report){
                    case '各部门人数统计表':
                        this.template = data1;
                        this.data = await reportDeptCount({ courtIds })
                    break;
                    case '法院工作人员基本情况统计表':
                        // this.template = data2;
						this.template = this.$getCurrentLib() ? data2 : data4;
                        this.data = await reportBaseInfo({ courtIds })
                    break;
                    case '法院工作人员最高学历情况统计表':
                        // this.template = data3;
						this.template = this.$getCurrentLib() ? data3 : data5;
                        this.data = await reportEducation({ courtIds })
                    break;
                    case '法院工作人员全日制学历情况统计表':
                        // this.template = data3;
						this.template = this.$getCurrentLib() ? data3 : data5;
                        this.data = await reportEducationFullTime({ courtIds })
                    break;
                }

            } catch (error) {
                console.log(error);
            }
            this.loading = false;
            
            
        },
        exportReport(){
            this.$refs.report.exportFile(this.params.report);
        },
        tdClick(data){
			const libName = this.$getCurrentLib()
			let path ='/hrms/report/query-user-infos'
			path = libName ? path.replace('/hrms', `/hrms/${libName}`) : path
			if(!this.userPermissions.includes(path)) {
				return this.$message.error('没有权限无权访问')
			}
            const { rowName , colName } = data;
            const route = this.$router.resolve({
                url: this.$route.url,
                query: {
                    rowName,
                    colName,
                    showInnerPage: 1,
                    courtIds: this.checkboxValues.join(','),
                    reportName: this.params.report
                }
            })
            window.open(route.href, '_blank');
        },
    },
}
</script>

<style lang="less" scoped>
.report-form{
    .inner-page-container{
        width: 100%;
        height: 100%;
        padding: 10px 20px 0;
        box-sizing: border-box;
    }
    .report-table{
        width: 100%;
        height: 100%;
        padding: 2px 0;
        display: flex;
		box-sizing: border-box;
        justify-content: space-between;
        background-color: #EFF3F7;
        .org-tree-container{
            
        }
        .content{
            background-color: #fff;
            flex-grow: 1;
            padding: 20px;
            overflow: auto;
            position: relative;
            .title{
                height: 90px;
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .prompt{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }
    
}
</style>