<template>
    <table class="table-box" border="1">
        <tbody>
            <tr v-for="(row,rowIndex) in list" :key="rowIndex">
                <td v-for="(col, colIndex) in row" :key="colIndex" v-if="col" :colspan="col.colspan" :rowspan="col.rowspan" :width="col.width">
                    <div :class="{'click': jumpable(col)}" @click="tdClick(col)">{{col && col.label}}</div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import XLSXStyle from 'xlsx-style';
import * as XLSX from 'xlsx'

export default {
    props: {
        template: {
            type: Object,
            default(){
                return {};
            }
        },
        data: {
            type: Array,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            list: [],
        }
    },
    computed: {
        params(){
            return {
                template: this.template,
                data: this.data,
            }
        }
    },
    watch: {
        'params.template': {
            handler(newVal, oldVal){
				if(newVal===oldVal) {
					if(this.params.template && this.params.data && this.params.data.length){
                    this.init();
                	}
				}
                
            },
            immediate: true
        }
    },
    methods: {
        init(){
            const list = [];
            const { row , col } = this.template.params;
            for (let i = 0; i < row; i++) {
                list.push([]);
                for (let j = 0; j < col; j++) {
                    list[i].push({});
                }
            }
            this.generateTable(this.template,list);
            // console.log(JSON.parse(JSON.stringify(list)));
            this.setTableCount(list, this.data);

            this.list = list;
        },
        generateTable(map,list){
            Object.keys(map).forEach(key => {
                if(!key.includes('x')){
                    return;
                }
                const i = key.split('x')[0] - 1;        //  行、列
                const j = key.split('x')[1] - 1;

                const item = map[key];
                list[i][j] = item;
                
                if(item.rowspan){
					item.unJumpable = true
                    for (let k = 1; k < item.rowspan; k++) {
                        list[i + k][j] = null;
                    }
                }
                if(item.colspan){
					item.unJumpable = true
                    for (let k = 1; k < item.colspan; k++) {
                        list[i][j + k] = null;
                    }
                }
                if(item.rowspan && item.colspan){
                    for (let m = 1; m < item.rowspan; m++) {
                        list[i + m][j] = null;
                        for (let n = 1; n < item.colspan; n++) {
                            list[i + m][j + n] = null;
                        }
                    }
                }
                //  填充参数，和后端获取值对应
                if(item.direction === 'col'){
					item.unJumpable = true
                    for (let k = i + 1; k < list.length; k++) {
                        if(list[k][j]){
                            list[k][j].colValue = item.value;
                            list[k][j].colName = item.name;
                        }
                    }
                }
                if(item.direction === 'row'){
					item.unJumpable = true
                    for (let k = j + 1; k < list[i].length; k++) {      //  列
                        if(list[i][k]){
                            list[i][k].params2 = item.value || item.label;
                            list[i][k].rowName = item.name;
                        }
                    }
                }

				if(item.direction === 'col-row') {
					item.rowName = item.name
					item.colName = map.params.lastColName
				}

                if(item.type === 'computed'){
                    if(item.direction === 'row'){
                        for (let k = j + 1; k < list[i].length; k++) {     //  循环当前行,k为列
                            const position = [];
                            item.computed.row.forEach(l => {
                                position.push([l,k + 1])
                            })
                            list[i][k].type = 'computed';
                            list[i][k].computed = {
                                type: item.computed.type,
                                position: position,
                            }
                        }
                    }
                } 
            })
        },
        setTableCount(list, data){
            list.forEach(row => {
                const target = data.find(item => {       //  找到当前行匹配到的数据
                    return row.find(col => col && col.label === item.positionName);
                });
                if(target){
                    row.forEach(col => {
                        if(col && col.colValue){
                            col.label = Math.floor(target[col.colValue]);
                            col.origin = target[col.colValue];
                        }
                    })
                }
            })

            

            for (let i = 0; i < list.length; i++) {
                const row = list[i];
                for (let j = 0; j < row.length; j++) {
                    const el = list[i][j];
                    const computed = el && el.computed;
                    if(computed && computed.position){
                        if(computed.type === 'total'){
								let count = 0;
                            	computed.position.forEach(([r,c]) => {
                                	count += Number(list[r-1][c-1].origin);
                            	})
                            	el.origin = el.label = Math.floor(count);
							// console.log('--------------',count)
                        }else if(computed.type === 'average'){
							const {position, col:c} = computed
							const rows = position.map(([r, _]) => r - 1)
							const total = rows.reduce((s, r) => {
								const m = list[r][c-1].origin
								const n = list[r][j].origin
								console.log(m,n)
								return s + m * n
							}, 0)
							const n = list[i][c-1].origin
							el.origin = el.label = n ? Math.floor(total / n) : n
                        }
                    }
                }
            }
        },
        exportFile(fileName){
			if(!this.list[0]) return this.$message.error('暂无数据，无法下载！！！')

            var wb = XLSX.utils.table_to_book(document.querySelector(`.table-box`));
            const sheet = wb.Sheets.Sheet1;
            // !cols--------------[[{wpx:100}]      //  代表第一列宽度
            // !merge-------------[{s:{r:0,c:0},e:{r:0,c:6}}]           //  s--start,e--end,r--row,c--col
            // A1
            
            this.list[0].forEach(item => {
                if(item && item.width){
                    if(item.colspan){
                        const width = item.width / item.colspan;
                        for (let i = 0; i < item.colspan; i++) {
                            sheet['!cols'].push({
                                wpx: width
                            })
                        }
                    }else{
                        sheet['!cols'].push({
                            wpx: item.width
                        })
                    }
                }
            })
            Object.keys(sheet).forEach(key => {
                if(!key.includes('!')){
                    sheet[key].s = {
                        alignment: {  // 对齐方式相关样式
                            vertical: 'center', //垂直对齐方式
                            horizontal: 'center', //水平对齐方式
                            wrapText: true,  //自动换行
                        },
                        font: { //字体相关样式
                            name: '宋体', //字体类型
                            sz: 11, //字体大小
                            color: { rgb: '#007AFF' }, //字体颜色
                            // bold: true, //是否加粗
                        }
                    }
                }
            })

            console.log(JSON.parse(JSON.stringify(sheet)));

            const wopts = {
                bookType: 'xlsx', // 要生成的文件类型
                bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
                type: 'binary'
            }
            const wbout  = XLSXStyle.write(wb,wopts);
            const blob = new Blob([this.s2ab(wbout)], {type: 'application/octet-stream'});

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xlsx`;
            a.click();
        },
        s2ab(wbout) {
            var buf = new ArrayBuffer(wbout.length)
            var view = new Uint8Array(buf)
            for (var i = 0; i !== wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xFF
            return buf
        },
		jumpable(col) {
			if(col.colName === '平均年龄' || col.unJumpable) return false
			return true
		},
        tdClick(col){
            if(this.jumpable(col)){
				// console.log(col)
                this.$emit('tdClick', col);
            }
        }
    },
    mounted(){},
}
</script>

<style lang="less" scoped>
.table-box{
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr{
        height: 40px;
    }
    td {
        text-align: center;  
    }
    .click{
        color: #007AFF;
        cursor: pointer;
    }
}
</style>